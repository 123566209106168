import React, { useContext, useRef, useState } from "react";
import { Norma } from "../models/Norma";
import { GraphContext } from "../context/GraphContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  TopReferredCard,
  TopReferredCardHeader,
} from "../ui/cards/TopReferredCard";
import {
  NormCyclesCard,
  NormCyclesCardHeader,
} from "../ui/cards/NormCyclesCard";
import NormaGraph from "../ui/graph/NormaGraph";
import NormaCard from "../ui/cards/NormaCard";
import IntroCard from "../ui/cards/IntroCard";
import ContactCard from "../ui/cards/ContactCard";
import EventEmitter from "events";

import "./TermTrend.css";

const TessutoNormativoPage: React.FC = () => {
  let visData = useContext(GraphContext);
  const eventEmitter = useRef(new EventEmitter());

  const [selectedNorm, setSelectedNorm] = useState<Norma | undefined>(
    undefined
  );

  return visData ? (
    <div id="content">
      <aside className="left">
        <IntroCard />
      </aside>
      <aside className="bottom left">
        <ContactCard />
      </aside>
      <main>
        <NormaGraph
          graph={visData.graph}
          eventEmitter={eventEmitter.current}
          onNormSelected={setSelectedNorm}
        />
      </main>
      <aside className="right">
        {selectedNorm && <NormaCard norma={selectedNorm} />}
        <Accordion>
          <AccordionTab
            className="no-padding"
            header={<TopReferredCardHeader />}
            style={{ padding: "0px !important" }}
          >
            <TopReferredCard
              onNormSelected={(norm) =>
                eventEmitter.current.emit("selectNodes", [norm])
              }
            />
          </AccordionTab>
          <AccordionTab
            className="no-padding"
            header={<NormCyclesCardHeader />}
            style={{ padding: "0px !important" }}
          >
            <NormCyclesCard
              onNormSelected={(norms) =>
                eventEmitter.current.emit("selectNodes", norms)
              }
            />
          </AccordionTab>
        </Accordion>
      </aside>
    </div>
  ) : null;
};

export default TessutoNormativoPage;
