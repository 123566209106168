import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

const TermExplanation: React.FC = () => {
  return (
    <Accordion
      multiple
      activeIndex={[0, 1]}
      className="px-4 mx-auto my-16 w-full max-w-7xl sm:px-6 md:px-8"
    >
      <AccordionTab header="Come funziona?">
        <p className="m-0">
          Dalla barra di ricerca in alto, è possibile cercare l'uso di una
          determinata parola all'interno di 53036 norme Italiane. Il risultato è
          un grafico che riporta il numero di norme in cui compare la parola per
          ogni anno, dal 1947 ad oggi.
        </p>
        <ol className="space-y-2 list-disc">
          <li className="list-item">
            è possibile ricercare più parole contemporaneamente, separandole da
            una virgola ","
          </li>
          <li className="list-item">
            Attualmente <b> si possono cercare solo parole singole</b>, non
            frasi.
          </li>
          <li className="list-item">
            Le norme italiane nell'indice, sebbene siano molte e tutte raccolte
            seguendo la vera distribuzione del numero di leggi Italiane
            pubblicate nel tempo, potrebbero non essere rappresentative della
            totalità. É probabile che la distribuzione risultante non sia del
            tutto veritiera. Quindi, non usare questo tool per trarre
            conclusioni certe!
          </li>
        </ol>
      </AccordionTab>
      <AccordionTab header="Dietro le quinte">
        <p className="m-0">
          In estrema sintesi, la ricerca avviene attraverso un inverted index
          distribuito. Il vocabolario di parole (196082 token differenti) viene
          utilizzato offline dall'utente. I documenti (il grafico in risultato)
          è invece salvato online e scaricato on-demand in base alla parola
          ricercata.
        </p>
        <p>
          L'intera funzionalità di questa pagina (compreso l'indexing) è
          realizzata SOLO tramite CDN e file statici. Un piccolo file di 540kb è
          l'unica cosa necessaria per cercare all'interno di 53036 norme.
        </p>
        <p>
          Leggi questo articolo per saperne di più, inclusi molti altri dettagli
          implementativi ed una spiegazione più approfondita.
        </p>
        <a
          href="https://marplex.medium.com/italian-laws-unigram-viewer-on-the-edge-with-cloudflare-pages-ef6c8cbe1630"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            label="Leggi di più"
            className="p-button-outlined"
            style={{ width: "100%", marginTop: "16px", marginBottom: "8px" }}
          ></Button>
        </a>
      </AccordionTab>
    </Accordion>
  );
};

export default TermExplanation;
