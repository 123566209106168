import React from "react";

const LoadingPage: React.FC = () => (
  <center
    style={{
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    Loading ...
  </center>
);

export default LoadingPage;
