import React, { useContext, useEffect, useState } from "react";
import { AccordionTab, AccordionTabProps } from "primereact/accordion";
import { ListBox, ListBoxChangeParams } from "primereact/listbox";
import { GraphContext } from "../../context/GraphContext";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import "../../index.css";

interface TopReferredCardParams {
  onNormSelected?: (urn: string) => void;
}

const TopReferredCardHeader: React.FC = () => (
  <React.Fragment>
    Norme più riferite
    <Tooltip target=".top10" position="left">
      Lista di norme più riferite da altre norme
    </Tooltip>
    <Tag
      className="top10"
      value="Top 10"
      style={{ marginLeft: "8px" }}
      icon="pi pi-info-circle"
    ></Tag>
  </React.Fragment>
);

const TopReferredCard: React.FC<TopReferredCardParams & AccordionTabProps> = (
  props: TopReferredCardParams
) => {
  const visData = useContext(GraphContext);
  const [list, setList] = useState<{ name: string; code: string }[]>([]);

  useEffect(() => {
    const topReferred = visData?.topReferred.map((it) => ({
      name: it.count + " | " + it.urn.replace("urn:nir:stato:", ""),
      code: it.urn,
    }));

    setList(topReferred!);
  }, [visData]);

  return (
    <ListBox
      optionLabel="name"
      options={list}
      style={{ border: "none", maxHeight: "40vh", overflowY: "auto" }}
      onChange={(e: ListBoxChangeParams) =>
        props.onNormSelected?.(e.value.code as string)
      }
    />
  );
};

export { TopReferredCard, TopReferredCardHeader };
