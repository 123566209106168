export function tokenize(term: string): string[] {
  return term
    .split(",")
    .map((it) => it.trim())
    .map((it) => it.toLowerCase())
    .map((it) => it.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
}

export function search(
  index: ArrayBuffer,
  token: string,
  tokenSize = 32
): number {
  let a = 0;
  let b = Math.floor(index.byteLength / tokenSize) - 1;
  const decoder = new TextDecoder("utf-8");

  //Good old binary search
  while (a <= b) {
    const mid = Math.floor((a + b) / 2);
    const bytes = new Uint8Array(index, tokenSize * mid, tokenSize);
    const str = decoder.decode(bytes.filter((x) => x !== 0));

    if (str === token) return mid;
    if (str > token) b = mid - 1;
    else a = mid + 1;
  }

  return -1;
}
