import { useEffect, useRef, useState } from "react";
import Graph from "graphology";
import {
  SigmaContainer,
  useLoadGraph,
  useRegisterEvents,
  useSetSettings,
  useSigma,
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import React from "react";
import { Attributes } from "graphology-types";
import { Norma } from "../../models/Norma";
import EventEmitter from "events";

interface Filters {
  dateFrom: Date;
  dateTo: Date;
  query: string;
}

interface NormaGraphProps {
  eventEmitter: EventEmitter;
  graph: Graph;

  onNormSelected: (norma?: Norma) => void;

  filters?: Filters;
}

const LoadGraph = (props: NormaGraphProps) => {
  const loadGraph = useLoadGraph();

  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const sigma = useSigma();

  let selectedNodeNeighbors = useRef<string[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<
    { showNeighbors: Boolean; nodes: string[] } | undefined
  >(undefined);

  useEffect(() => {
    const screenWidth = window.screen.availWidth;

    loadGraph(props.graph);
    sigma.getGraph().updateEachNodeAttributes((node, attr) => {
      attr.size *= screenWidth / 1920;
      return attr;
    });

    // Register the events
    registerEvents({
      clickNode: (event) => {
        const graph = sigma.getGraph();
        const attributes = graph.getNodeAttributes(event.node);

        selectedNodeNeighbors.current = graph.neighbors(event.node);
        setSelectedNodes({ showNeighbors: true, nodes: [event.node] });

        const norma = new Norma();
        norma.label = attributes.label;
        norma.urn = event.node;
        norma.referredBy = attributes.referredBy;
        norma.refersTo = attributes.refersTo;

        props.onNormSelected(norma);
      },
      clickStage: () => {
        setSelectedNodes(undefined);
        props.onNormSelected(undefined);
      },
    });
  }, []);

  useEffect(() => {
    props.eventEmitter.on("selectNodes", (nodes: string[]) => {
      if (nodes && nodes.length > 0) {
        const graph = sigma.getGraph();

        selectedNodeNeighbors.current = nodes.flatMap((x) =>
          graph.neighbors(x)
        );
        setSelectedNodes({ showNeighbors: false, nodes: nodes! });

        const attributes = graph.getNodeAttributes(nodes![0]);

        const norma = new Norma();
        norma.label = attributes.label;
        norma.urn = nodes![0];
        norma.referredBy = attributes.referredBy;
        norma.refersTo = attributes.refersTo;

        props.onNormSelected(norma);
      }
    });

    props.eventEmitter.on("reset", () => sigma.getCamera().animatedReset());
  }, [props.eventEmitter]);

  useEffect(() => {
    setSettings({
      renderLabels: false,
      labelDensity: 0,
      labelRenderedSizeThreshold: 20,
      hideEdgesOnMove: true,
      hideLabelsOnMove: true,
      minCameraRatio: 0.3,
      maxCameraRatio: 1.1,
      nodeReducer: (node, data) => {
        const newData: Attributes = {
          ...data,
          highlighted: data.highlighted || false,
        };

        if (selectedNodes) {
          if (
            selectedNodes.nodes.includes(node) ||
            (selectedNodes.showNeighbors &&
              selectedNodeNeighbors.current.includes(node))
          ) {
            newData.highlighted = true;
          } else {
            newData.color = "#E2E2E2";
            newData.highlighted = false;
          }
        }
        return newData;
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, hidden: false };

        if (
          selectedNodes &&
          selectedNodes.showNeighbors &&
          !graph.extremities(edge).some((x) => selectedNodes.nodes.includes(x))
        )
          newData.hidden = true;
        else if (
          selectedNodes &&
          !selectedNodes.showNeighbors &&
          !graph.extremities(edge).every((x) => selectedNodes.nodes.includes(x))
        )
          newData.hidden = true;

        return newData;
      },
    });
  }, [selectedNodes]);

  return null;
};

const NormaGraph: React.FC<NormaGraphProps> = (props: NormaGraphProps) => {
  return (
    <SigmaContainer style={{ height: "100%", width: "100%" }}>
      <LoadGraph {...props} />
    </SigmaContainer>
  );
};

export default NormaGraph;
