import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./IntroCard.css";
import { Button } from "primereact/button";

const IntroCard: React.FC = () => {
  return (
    <Accordion activeIndex={0}>
      <AccordionTab header="Tessuto normativo Italiano">
        <h3 style={{ marginTop: "0px" }}>Come funziona?</h3>
        <p>
          Ogni cerchio è una norma. Il colore del cerchio ne indica il tipo. La
          luminosità del colore indica la data di pubblicazione, più è chiaro e
          più la norma è recente.
        </p>
        <ul>
          <li>
            <div className="circle green"></div>
            Costituzione
          </li>
          <li>
            <div className="circle red"></div>
            Decreto legge
          </li>
          <li>
            <div className="circle yellow"></div>
            Decreto legislativo
          </li>
          <li>
            <div className="circle blue"></div>
            Legge
          </li>
        </ul>

        <p>
          Il testo di ogni norma, al suo interno, fa riferimento a successive
          altre norme. Questi collegamenti vengono visualizzati tramite una
          linea con freccia.
        </p>

        <img
          src="explainer.png"
          alt="Legge che fa riferimento a costituzione"
        />

        <p>
          Premi su un cerchio per visualizzare tutte le altre norme collegate a
          quest'ultima.
        </p>

        <h3>Norme visualizzate</h3>

        <p>
          Le norme analizzate sono 12.677 (su un totale stimato di circa 111.000
          leggi 😲). I dati presenti su questa pagina sono a puro scopo
          educativo, alcune norme potrebbero essere state classificate in
          maniera errata.
        </p>

        <a
          href="https://medium.com/@marplex/decifrare-la-complessit%C3%A0-delle-leggi-italiane-unanalisi-di-13000-norme-a248117eb8bd"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            label="Leggi di più"
            className="p-button-outlined"
            style={{ width: "100%", marginTop: "16px", marginBottom: "8px" }}
          ></Button>
        </a>
      </AccordionTab>
    </Accordion>
  );
};

export default IntroCard;
