import { BrowserRouter, Route, Routes } from "react-router-dom";
import GraphProvider from "../ui/GraphProvider";
import IndexProvider from "../ui/IndexProvider";
import LoadingPage from "./Loading";
import MainPage from "./Main";

import { useMobile } from "../hooks/useMobile";

import "./App.css";
import TermTrendPage from "./TermTrend";
import TessutoNormativoPage from "./TessutoNormativo";

function App() {
  const isMobile = useMobile();

  return (
    <Routes>
      <Route
        path="/tessuto-normativo"
        element={
          <>
            {isMobile && (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "32px",
                }}
              >
                <span>
                  Questa visualizzazione non è ancora disponibile per
                  dispositivi mobili.
                </span>
                <b style={{ marginTop: "16px" }}>
                  Apri questa pagina su desktop per procedere
                </b>
              </div>
            )}
            {!isMobile && (
              <div>
                <GraphProvider renderLoading={<LoadingPage />}>
                  <TessutoNormativoPage />
                </GraphProvider>
              </div>
            )}
          </>
        }
      />
      <Route
        path="/term-trend"
        element={
          <IndexProvider renderLoading={<LoadingPage />}>
            <TermTrendPage />
          </IndexProvider>
        }
      />
      <Route
        path="/"
        element={
          <div>
            <MainPage />
          </div>
        }
      />
    </Routes>
  );
}

export default App;
