import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { useContext, useEffect, useRef, useState } from "react";
import { tokenize, search } from "../libs/indexer";
import { loadDistribution } from "../libs/downloader";
import { IndexContext } from "../context/IndexContext";
import { ProgressBar } from "primereact/progressbar";
import TermTooltip from "../ui/termtrend/TermTooltip";
import TermExplanation from "../ui/termtrend/TermExplanation";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

interface Term {
  term: string;
  color: string;
}

interface LineChartData {
  terms: Term[];
  distribution: { year: number; values: number[] }[];
}

const TermTrendPage: React.FC = () => {
  let index = useContext(IndexContext)!;
  const colors = [
    "#20A4F3",
    "#EA3546",
    "#009246",
    "#03191E",
    "#4E6151",
    "#4C2B36",
  ];
  let tokens: string[] = [];

  let [query, setQuery] = useState("Lire, Euro");
  let [chartData, setChartData] = useState<LineChartData>();
  let [isLoading, setLoading] = useState(false);

  useEffect(() => {
    searchDocuments().then();
  }, []);

  async function searchDocuments() {
    setLoading(true);

    tokens = tokenize(query);
    const offsets = tokens.map((token) => search(index, token));
    console.log(offsets);

    const distributions = [];
    for (let offset of offsets) {
      if (offset === -1) distributions.push(new Array(76).fill(0));
      else distributions.push(Array.from(await loadDistribution(offset)));
    }

    let graphData = [];
    for (let i = 0; i < 76; i++) {
      graphData.push({
        year: i + 1947,
        values: distributions.map((it) => it[i]),
      });
    }

    setLoading(false);
    setChartData({
      terms: query
        .split(",")
        .map((x) => x.trim())
        .map((x, i) => ({
          color: colors[i % colors.length],
          term: x,
        })),
      distribution: graphData,
    });
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col px-4 mx-auto w-full max-w-7xl sm:px-6 md:px-8">
        <h1 className="mx-auto mb-4 text-2xl font-medium sm:mx-0">
          🇮🇹 TermTrend
        </h1>

        <div className="flex flex-row justify-center">
          <span className="w-full p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && searchDocuments()}
              placeholder="Cerca parole"
              className="w-full !rounded-none sm:!rounded-md"
            />
          </span>
        </div>
      </div>

      <Divider className="!mb-0" />

      <ProgressBar
        mode="indeterminate"
        className="w-full rounded-none"
        style={{ height: "2px", opacity: isLoading ? 1 : 0 }}
      />

      {chartData ? (
        <ResponsiveContainer
          width={"100%"}
          height={500}
          min-width={300}
          className="px-4 mt-12 -ml-16 max-w-7xl sm:-ml-28 sm:px-6 md:px-8"
        >
          <LineChart data={chartData.distribution}>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip content={<TermTooltip terms={chartData.terms} />} />
            <YAxis />
            <Legend iconType="circle" />
            <XAxis dataKey="year" />
            {chartData.terms.map((term, i) => (
              <Line
                key={i}
                name={term.term}
                type="monotone"
                dataKey={(x) => x.values[i]}
                stroke={term.color}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div style={{ height: "500px" }}></div>
      )}

      <TermExplanation />
    </div>
  );
};

export default TermTrendPage;
