declare global {
  interface Window {
    isLittleEndian: boolean;
  }
}

//Swap little-endian to big-endian
export function swap16(val: number): number {
  return ((val & 0xff) << 8) | ((val >> 8) & 0xff);
}

export function isLittleEndian(): boolean {
  if (window.isLittleEndian !== undefined) return window.isLittleEndian;

  var buf = new ArrayBuffer(4);
  var buf8 = new Uint8ClampedArray(buf);
  var data = new Uint32Array(buf);
  data[0] = 0x0f000000;

  window.isLittleEndian = buf8[0] !== 0x0f;
  return window.isLittleEndian;
}
