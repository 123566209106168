import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Norma, NormaType, NormaValue } from "../../models/Norma";
import HorizontalChart from "../graph/HorizontalChart";

const NormaCard: React.FC<{ norma: Norma }> = (props: { norma: Norma }) => {
  const [referredValues, setReferredValues] = useState<Array<NormaValue>>([]);
  const [refersValues, setRefersValues] = useState<Array<NormaValue>>([]);

  const [refersToCount, setRefersToCount] = useState(0);
  const [referredByCount, setReferredByCount] = useState(0);

  useEffect(() => {
    const refersValueTemp = Object.keys(props.norma.refersTo).map<NormaValue>(
      (x) => ({
        type: NormaType.fromNumber(parseInt(x)),
        value: props.norma.refersTo[x],
      })
    );

    const referredValuesTemp = Object.keys(
      props.norma.referredBy
    ).map<NormaValue>((x) => ({
      type: NormaType.fromNumber(parseInt(x)),
      value: props.norma.referredBy[x],
    }));

    setRefersValues(refersValueTemp);
    setReferredValues(referredValuesTemp);

    if (refersValueTemp.length === 0) setRefersToCount(0);
    else {
      setRefersToCount(
        refersValueTemp.map((it) => it.value).reduce((a, b) => a + b)
      );
    }

    if (referredValuesTemp.length === 0) setReferredByCount(0);
    else {
      setReferredByCount(
        referredValuesTemp.map((it) => it.value).reduce((a, b) => a + b)
      );
    }
  }, [props.norma]);

  return (
    <Accordion>
      <AccordionTab
        header={
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{props.norma.getDisplayName()}</span>
              <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                {props.norma.getType().getDisplayName()}
              </span>
            </div>
          </React.Fragment>
        }
      >
        <span>
          Riferita da <b>{referredByCount} norme</b>
        </span>
        <HorizontalChart values={referredValues} />

        <span>
          Fa riferimento a <b>{refersToCount} norme</b>
        </span>
        <HorizontalChart values={refersValues} />

        <a
          href={"https://www.normattiva.it/uri-res/N2Ls?" + props.norma.urn}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            label="Apri in Normattiva"
            className="p-button-outlined"
            style={{ width: "100%", marginTop: "16px", marginBottom: "8px" }}
          ></Button>
        </a>
      </AccordionTab>
    </Accordion>
  );
};

export default NormaCard;
