class NormaType {
  static COSTITUZIONE: NormaType = new NormaType(0, "#00CC6A");
  static DECRETO_LEGISLATIVO: NormaType = new NormaType(1, "#FFB900");
  static DECRETO_LEGGE: NormaType = new NormaType(2, "#FA4F40");
  static LEGGE: NormaType = new NormaType(3, "#0063B1");
  static NONE: NormaType = new NormaType(4, "#E3008C");

  constructor(public value: number, public color: string) {}

  getDisplayName(): string {
    switch (this.value) {
      case 0:
        return "Costituzione";
      case 1:
        return "Decreto Legislativo";
      case 2:
        return "Decreto Legge";
      case 3:
        return "Legge";
      default:
        return "Indefinito";
    }
  }

  static fromNumber(number: number): NormaType {
    switch (number) {
      case 0:
        return this.COSTITUZIONE;
      case 1:
        return this.DECRETO_LEGISLATIVO;
      case 2:
        return this.DECRETO_LEGGE;
      case 3:
        return this.LEGGE;
      default:
        return this.NONE;
    }
  }

  static fromUrn(urn: string): NormaType {
    if (urn.includes("decreto.legge")) return NormaType.DECRETO_LEGGE;
    if (urn.includes("legge")) return NormaType.LEGGE;
    if (urn.includes("costituzione")) return NormaType.COSTITUZIONE;
    if (urn.includes("decreto.legislativo"))
      return NormaType.DECRETO_LEGISLATIVO;
    else return NormaType.NONE;
  }
}

class Norma {
  urn!: string;
  label!: string;

  referredBy!: Record<string, number>;
  refersTo!: Record<string, number>;

  getType(): NormaType {
    return NormaType.fromUrn(this.urn);
  }

  getDisplayName(): string {
    const shortVersion = this.urn.replace("urn:nir:stato:", "");
    const tokens = shortVersion.split(";");

    const number = tokens[1];
    const date = tokens[0].split(":")[1].replaceAll("-", "/");
    return `N° ${number} del ${date}`;
  }
}

type NormaValue = {
  type: NormaType;
  value: number;
};

export { NormaType, Norma };
export type { NormaValue };
