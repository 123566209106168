import React from "react";
import { TooltipProps } from "recharts";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";

const TermTooltip = ({
  active,
  payload,
  label,
  terms,
}: TooltipProps<number, string> & { terms: any[] }) => {
  if (active && payload && payload.length) {
    return (
      <Panel header={label} className="outline-none">
        <div className="w-64">
          {payload[0].payload.values.map((value: number, i: number) => (
            <div className="flex justify-center items-baseline" key={i}>
              <div
                className="mr-2 w-2 h-2 rounded-full"
                style={{
                  background: terms[i].color,
                }}
              />
              <span className="grow">{terms[i].term}</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </Panel>
    );
  }

  return null;
};

export default TermTooltip;
