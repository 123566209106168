import React from "react";
import { useEffect } from "react";
import { downloadGraph, downloadIndex } from "../libs/downloader";
import { GraphContext } from "../context/GraphContext";
import { useState } from "react";
import { VisualizationData } from "../models/VisualizationData";
import { IndexContext } from "../context/IndexContext";

interface IndexProviderProps {
  renderLoading: React.ReactElement;
}

const IndexProvider: React.FC<React.PropsWithChildren<IndexProviderProps>> = (
  props: React.PropsWithChildren<IndexProviderProps>
) => {
  const [oneGramIndex, setOneGramIndex] = useState<ArrayBuffer | undefined>();

  useEffect(() => {
    async function initData(): Promise<any> {
      return await downloadIndex();
    }

    async function init() {
      const data = await initData();
      setOneGramIndex(data);
    }

    init().catch(console.error);
  }, []);

  return (
    <>
      {!oneGramIndex && props.renderLoading}
      {oneGramIndex && (
        <IndexContext.Provider value={oneGramIndex}>
          {props.children}
        </IndexContext.Provider>
      )}
    </>
  );
};

export default IndexProvider;
