import React, { useContext, useEffect, useState } from "react";
import { AccordionTab } from "primereact/accordion";
import { ListBox, ListBoxChangeParams } from "primereact/listbox";
import { GraphContext } from "../../context/GraphContext";
import { Tooltip } from "primereact/tooltip";
import "../../index.css";

interface NormCyclesCardParams {
  onNormSelected?: (urns: string[]) => void;
}

const NormCyclesCardHeader: React.FC = () => (
  <React.Fragment>
    <span className="cycles_header"></span>
    Norme con riferimenti circolari
    <Tooltip target=".cycles_header" position="left">
      Lista di norme i cui riferimenti ritornano a se stesse
    </Tooltip>
  </React.Fragment>
);

const NormCyclesCard: React.FC<NormCyclesCardParams> = (
  props: NormCyclesCardParams
) => {
  const visData = useContext(GraphContext);
  const [list, setList] = useState<{ name: string; code: string[] }[]>([]);

  useEffect(() => {
    const normCycles = visData?.nodeCycles.map((it) => ({
      name: it[0][0].replace("urn:nir:stato:", ""),
      code: it,
    }));

    setList(normCycles!);
  }, [visData]);

  return (
    <ListBox
      optionLabel="name"
      options={list}
      style={{ border: "none", maxHeight: "40vh", overflowY: "auto" }}
      onChange={(e: ListBoxChangeParams) =>
        props.onNormSelected?.((e.value.code as string[]).map((x) => x[0]))
      }
    />
  );
};

export { NormCyclesCard, NormCyclesCardHeader };
