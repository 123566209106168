import { isLittleEndian, swap16 } from "./utils";
import brotliPromise from "brotli-wasm";

declare global {
  interface Window {
    documentChunks: Map<number, ArrayBuffer>;
  }
}

export async function downloadGraph(name: string): Promise<any> {
  const res = await fetch(`/${name}`);
  return await res.json();
}

export async function downloadIndex(): Promise<ArrayBuffer> {
  const res = await fetch("/static/index.br");
  const compressed = await res.arrayBuffer();

  const brotli = await brotliPromise;
  return brotli.decompress(new Uint8Array(compressed)).buffer;
}

export async function loadDistribution(offset: number): Promise<Uint16Array> {
  const chunk = Math.floor(offset / 19608.2);
  let buffer = new ArrayBuffer(0);

  if (window.documentChunks === undefined) window.documentChunks = new Map();
  if (window.documentChunks.has(chunk))
    buffer = window.documentChunks.get(chunk)!;
  else {
    const res = await fetch(`/static/documents/documents_${chunk}.br`);
    const compressed = await res.arrayBuffer();

    const brotli = await brotliPromise;
    buffer = brotli.decompress(new Uint8Array(compressed)).buffer;
    window.documentChunks.set(chunk, buffer);
  }

  const rowSize = 76 * 2;
  const distribution = new Uint16Array(
    buffer,
    Math.floor(offset % 19608) * rowSize,
    rowSize
  );

  if (isLittleEndian()) return distribution.map(swap16);
  else return distribution;
}
