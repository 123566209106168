import React from "react";

const MainPage: React.FC = () => (
  <center className="flex flex-col justify-center items-center w-screen h-screen">
    <div className="my-12">
      <h1 className="leading-3">Marco Cimolai's Labs</h1>
      <span>🧪 Avvia l'esperimento che preferisci</span>
    </div>

    <div className="flex flex-col space-y-4 sm:space-x-4 sm:space-y-0 sm:flex-row">
      <a href="/tessuto-normativo" className="no-underline">
        <div className="flex flex-col rounded-md border-gray-300 border-solid border-[1px] items-start p-6 w-72 hover:bg-gray-200 hover:shadow-md transition-all">
          <img
            src="/logo128.png"
            className="mb-4 w-14"
            alt="Icona stilizzata del tessuto normativo Italiano"
          />
          <span className="text-lg font-medium text-black">
            Tessuto normativo
          </span>
          <span className="text-gray-800 text-md">
            Le leggi Italiane in grafo
          </span>
        </div>
      </a>

      <a href="/term-trend" className="no-underline">
        <div className="flex flex-col rounded-md border-gray-300 border-solid border-[1px] items-start p-6 w-72 hover:bg-gray-200 hover:shadow-md transition-all">
          <img
            src="/termtrend.png"
            className="mb-4 h-14"
            alt="Icona stilizzata del tessuto normativo Italiano"
          />
          <span className="text-lg font-medium text-black">Term Trend</span>
          <span className="text-gray-800 text-md text-start">
            Le parole nel tempo
          </span>
        </div>
      </a>
    </div>
  </center>
);

export default MainPage;
