import React from "react";
import { useEffect } from "react";
import { downloadGraph } from "../libs/downloader";
import { GraphContext } from "../context/GraphContext";
import { useState } from "react";
import { VisualizationData } from "../models/VisualizationData";

interface GraphProviderProps {
  renderLoading: React.ReactElement;
}

const GraphProvider: React.FC<React.PropsWithChildren<GraphProviderProps>> = (
  props: React.PropsWithChildren<GraphProviderProps>
) => {
  const [visData, setVisData] = useState<VisualizationData | undefined>();

  useEffect(() => {
    async function initData(): Promise<any> {
      return await downloadGraph("data.json");
    }

    async function init() {
      const data = await initData();
      setVisData(data);
    }

    init().catch(console.error);
  }, []);

  return (
    <>
      {!visData && props.renderLoading}
      {visData && (
        <GraphContext.Provider value={visData}>
          {props.children}
        </GraphContext.Provider>
      )}
    </>
  );
};

export default GraphProvider;
