import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./ContactCard.css";

const ContactCard: React.FC = () => {
  return (
    <Accordion expandIcon="" collapseIcon="" className="contact">
      <AccordionTab
        header={
          <React.Fragment>
            <span>Made with </span>
            <i
              className="pi pi-heart-fill"
              style={{ color: "var(--red-500)" }}
            ></i>
            <span> by Marco Cimolai</span>
          </React.Fragment>
        }
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <a
            href="https://github.com/Marplex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/static/v1?label=GitHub&message=marplex&color=005cb2&style=flat-square"
              alt="Github badge"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/marco-cimolai-74b9a9218"
            style={{ marginLeft: "4px" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/-marcocimolai-blue?style=flat-square&logo=Linkedin&logoColor=white&link=https://www.linkedin.com/in/marco-cimolai-74b9a9218"
              alt="Linkedin badge"
            />
          </a>
          <a
            href="mailto:cimolaimarco01@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.shields.io/badge/-cimolaimarco01@gmail.com-c14437?style=flat-square&logo=Gmail&logoColor=white&link=mailto:cimolaimarco01@gmail.com"
              alt="Mail badge"
            />
          </a>
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default ContactCard;
