import React from "react";
import { Chart } from "primereact/chart";
import { NormaValue } from "../../models/Norma";

interface HorizontalChartProps {
  values: Array<NormaValue>;
  labels?: Array<string>;
}

const HorizontalChart: React.FC<HorizontalChartProps> = (
  props: HorizontalChartProps
) => (
  <Chart
    type="bar"
    style={{ width: "100%", height: "40px" }}
    data={{
      labels: [""],
      datasets: props.values.map((it) => ({
        label: it.type.getDisplayName(),
        data: [it.value],
        backgroundColor: it.type.color,
        barThickness: 20,
      })),
    }}
    options={{
      indexAxis: "y",
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          display: false,

          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          display: false,
          min: -1,
          max: 1,
          grid: {
            display: false,
          },
        },
      },
    }}
  />
);

export default HorizontalChart;
